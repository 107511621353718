// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
	position: fixed;
	z-index: 500;
	top: 0;
	left: 0;
	width: 100%;
  @include media-breakpoint-down(md) {
    position: absolute;
  }
	.top {
		padding-top: 20px;
    padding-bottom: 30px;
    height: 160px;
    background-color: #fff;
    border-bottom: 1px solid #e4e4e4;
    @include transition(all .35s ease-in-out);
    .container-fluid {
      padding-left: 50px;
      padding-right: 50px;
    }
    .left {
      padding-top: 15px;
    }
    p {
      margin: 0;
      color: #c2c2c2;
      font-size: 0.75rem;
      font-style: italic;
      font-weight: 600;
      letter-spacing: .75px;
      line-height: 2em;
    }
	}
  &.scrolled {
    .top, .secondary-nav {
      margin-top: -160px;
      @include transition(all .35s ease-in-out);
    }
  }
}
