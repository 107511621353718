// Variables
//


//
// Color system
//

$theme-colors: (
  primary: #3baf29,
  secondary: #94d60a,
  tertiary: #ffa409,
  quaternary: #c1d601,
  success: #28a745,
  info: #17a2b8,
  warning: #ffc107,
  danger: #dc3545,
  light: #f8f9fa,
  medium: #ebebeb,
  dark: #343a40,
  darktext: #8d8d8d,
  blue: #558aa7,
  pink: #ba006a
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', sans-serif;
