// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin-bottom: (1rem / 2);
	color: inherit;
	line-height: 1.1;
	font-family: inherit;
	font-weight: 500;
}
h1,.h1{
	font-size: 2.5rem;
}
h1.main-title {
  text-align: center;
  margin-top: -270px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 600;
}
h2,.h2 {
	font-size: 2rem;
  color: map_get($theme-colors, quaternary);
  font-weight: 600;
  margin-bottom: 30px;
}
h3,.h3 {
	font-size: 1.75rem;
}
h4,.h4 {
	font-size: 1.5rem;
}
h5,.h5 {
	font-size: 1.25rem;
}
h6,.h6 {
	font-size: 1rem;
}

p {
  color: map_get($theme-colors, darktext);
  line-height: 2.2rem;
}

ul {
	li {
    margin-left: 20px;
    color: map_get($theme-colors, darktext);
	}
}
ol {
  li {
    color: map_get($theme-colors, darktext);
  }
}

a {
	color: map-get($theme-colors, primary);
	text-decoration: underline;
	&:hover {
		color: map-get($theme-colors, primary);
		text-decoration: none;
	}
}

.evidence {
  margin: 20px 0;
  padding: 20px 0;
  background: map-get($theme-colors, primary);
  color: #fff;
  text-align: center;
  font-weight: bold;
  a {
    color: #fff;
  }
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
