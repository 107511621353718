// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.main-footer {
	.newsletter {
		padding-top: 85px;
		padding-bottom: 80px;
		font-size: 0;
		text-align: center;
		h2, .form-newsletter {
			display: inline-block;
			vertical-align: middle;
			margin: 10px;
		}
		h2 {
			padding: 12px 0;
			padding-left: 80px;
			background-image: url(../images/icon-newsletter.png);
			background-position: left center;
			background-repeat: no-repeat;
			color: map-get($theme-colors, quaternary);
			font-size: 1.9rem;
			font-weight: 700;
			line-height: 1.2em;
		}
    .newsletter-archives {
      text-align: left;
      padding: 0 0 0 220px;
      @include media-breakpoint-down(sm) {
        padding: 0;
        text-align: center;
      }
      a {
        font-size: 1.2rem;
      }
    }
		form {
			font-size: 0;
			.form-item, .form-actions {
				display: inline-block;
				vertical-align: middle;
				margin: 5px;
			}
			label {
				display: none;
			}
			.form-text {
			    border: 2px solid transparentize(#e1e1e1, .7);
			    border-radius: 4px;
			    height: 60px;
			    background-color: #fff;
			    color: #8d8d8d;
			    font-size: 0.9rem;
			    font-weight: 600;

			    // Placeholder
				&::placeholder {
					color: #8d8d8d;
				}
			}
			.form-item {
				width: 260px;
				max-width: 100%;
			}
		}
	}
	.footer-top {
		background-color: map-get($theme-colors, primary);
    padding: 30px 0;
		color: #fff;
		.right {
			p {
				margin: 0 0 20px;
				color: #a3d39c;
				font-size: 0.75rem;
				font-style: italic;
				font-weight: 600;
				letter-spacing: .75px;
				line-height: 1.35em;
			}
		}
    p, a {
      color: #fff;
    }
    .tel {
      color: #fff !important;
    }
	}
}
.copyright {
  text-align: center;
  padding: 20px 0;
  p {
    font-size: .85rem;
    color: map_get($theme-colors, dark);
  }
  a {
    color: map_get($theme-colors, dark);
  }
}
