.contact-form {
  background: map_get($theme-colors, primary);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 40px;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
  input[type="text"], input[type="email"] {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    height: auto;
    font-size: 1rem;
    padding: 10px 15px;
  }
  input[type="submit"] {
    background-color: lighten(map-get($theme-colors, primary), 5%);
    float: right;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:hover {
      background-color: darken(map-get($theme-colors, primary), 5%);
    }
  }
}
.contact {
  h4 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .address {
    line-height: 2rem;
    color: map_get($theme-colors, dark);
    font-size: 1rem;
    @include media-breakpoint-down(lg) {
      margin-top: 40px;
    }
    iframe {
      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }
  }
  iframe {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
}
.page-node-done {
  .webform-confirmation{
    @extend .container;
    background: #fff;
    padding: 40px 40px 20px 40px;
  }
  .links {
    @extend .container;
    background: #fff;
    padding: 0 40px 100px 40px;
  }
}
