.view-institutions {
  .view-filters {
    background: map_get($theme-colors, primary);
    padding: 30px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
    h3 {
      color: #fff;
      margin-bottom: 0;
    }
    .views-exposed-widgets {
      display: flex;
      justify-content: flex-start;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
      .views-widget-filter-field_institutions_activites_value {
        label {
          visibility: hidden;
        }
      }
      .views-exposed-widget {
        margin-right: 20px;
        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 20px;
        }
        label {
          color: #fff;
        }
        .form-item {
          margin-bottom: 0;
          &.form-item-a {
            position: relative;
            &:after {
              content: '\f002';
              font-family: Fontawesome;
              position: absolute;
              right: 10px;
              top: 10px;
              color: #fff;
            }
            input{
              padding-right: 50px;
            }
          }
        }
        .form-item-field-institutions-activites-value {
          position: relative;
          /*&:after {
            content: '\f002';
            font-family: Fontawesome;
            font-size: 1.2rem;
            position: absolute;
            right: 10px;
            top: 10px;
            color: #fff;
          }*/
        }
        input[type="text"] {
          background: transparent;
          border: 1px solid #fff;
          color: #fff;
          outline: none;
          margin-top: 30px;
          position: relative;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
        }
        .form-type-select {
          border: 1px solid #fff;
          color: #fff;
          position: relative;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          &:after {
            content: '\f107';
            font-family: Fontawesome;
            position: absolute;
            top: 12px;
            right: 10px;
            padding-left: 10px;
            border-left: 1px solid #fff;
            z-index: 2;
          }
          select {
            color: #fff;
            border: none;
            box-shadow: none;
            background-color: transparent;
            background-image: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            outline: none;
            position: relative;
            z-index: 3;
            option {
              color: map_get($theme-colors, primary);
            }
          }
        }
        input.form-submit {
          background-color: darken(map-get($theme-colors, primary), 10%);
          border-color: darken(map-get($theme-colors, primary), 10%);
          margin-top: 30px;
          padding: 15px 30px;
          @include transition(all .2s eas-in-out);
          @include media-breakpoint-down(md) {
            margin-top: 0;
          }
          &:hover {
            color: map_get($theme-colors, primary);
            background: #fff;
            border-color: #fff;
          }
        }
      }
    }
  }
  .views-row {
    border: 1px solid map_get($theme-colors, medium);
    padding: 40px;
    background: #fff;
    margin-top: -1px;
    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
    }
    .insti-pic {
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .insti-text {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      .btn {
        @include media-breakpoint-down(sm) {
          margin-top: 10px;
        }
      }
    }
    h4 {
      color: map_get($theme-colors, primary);
    }
    span {
      display: block;
    }
  }
}
