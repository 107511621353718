.view-actualites {
  position: relative;
  text-align: left;
  .view-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      justify-content: space-between;
    }
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    .views-row {
      width: 30%;
      margin: 0 15px 50px 15px;
      @include media-breakpoint-down(md) {
        width: 40%;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0 10px;
      }
      &:first-child(), &:nth-child(4n) {
        margin-left: 0;
        @include media-breakpoint-down(sm) {
          margin-left: 10px;
        }
      }
      &:last-child(), &:nth-child(3n) {
        margin-right: 0;
        @include media-breakpoint-down(sm) {
          margin-right: 10px;
        }
      }
      .news-item {
        position: relative;
        top: 0;
        @include transition(all .2s ease-in-out);
        &:hover {
          top: -30px;
        }
      }
      .news-pic {
        height: 260px;
        background-size: cover;
        background-repeat:no-repeat;
        background-position: center;
      }
      h3 {
        font-size: 1.2rem;
        color: map-get($theme-colors, quaternary);
        margin: 30px 0 10px 0;
      }
      p {
        font-size: .9rem;
        color: map_get($theme-colors, dark);
        line-height: 2rem;
      }
    }
  }
}
.agenda-other {
  background: map_get($theme-colors, quaternary);
  padding: 100px 0;
  .container {
    padding: 0;
  }
  .round-content {
    background: transparent;
    padding: 0;
    .event-content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
      > div {
        margin: 0 0 80px 0;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        padding: 40px;
        width: 30%;
        background: rgba(0,0,0, .1);
        color: #fff;
        @include media-breakpoint-down(md) {
          width: 100%;
          margin: 40px 0;
        }
        &:nth-child(3n+2) {
          margin-left: 40px;
          margin-right: 40px;
        }
        p {
          color: #fff;
        }
        a {
          color: #fff;
        }
        h3 {
          font-weight: 600;
        }
        .date {
          margin-top: -50px;
          margin-bottom: 20px;
        }
        .jour {
          font-size: 7rem;
          font-weight: 800;
        }
        .mois_annee {
          font-size: 1.7rem;
          font-weight: 800;
        }
        .category {
          text-transform: uppercase;
          font-size: .9rem;
        }
      }
    }
    .event-title {
      display: none;
    }
  }
  .all-link {
    text-align: right;
    margin-top: 50px;
    a {
      color: #fff;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
}
