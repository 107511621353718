// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------

.content-galerie {
	padding: 30px 0;
	&::after {
		display: block;
		content: "";
		clear: both;
	}
	.owl-nav {
		float: left;
		margin: 0;
		[class*="owl-"] {
			margin: 0;
			padding: 5px 10px;
			background-color: transparent;
			border-radius: 0;
			color: map-get($theme-colors, primary);
			font-size: 1.5rem;
			line-height: 1em;
			&:hover {
				background-color: map-get($theme-colors, primary);
			}
		}
	}
	.owl-dots {
		float: right;
		.owl-dot {
			span {
				margin: 10px 5px;
				background-color: #d6d6d6;
			}
			&.active, &:hover {
				span {
					background-color: map-get($theme-colors, primary);
				}
			}
		}
	}
  .swiper-gallery {
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    .swiper-slide {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .swiper-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .swiper-pagination-bullet {
      background: map_get($theme-colors, quaternary);
    }
    .swiper-pagination-bullet-active {
      background: map_get($theme-colors, quaternary);
    }
  }
}
