// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
nav#nav {
    background-color: map-get($theme-colors, secondary);
}

.main-nav {
    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        text-align: center;
        @include clearfix;

        > li {
            display: inline-block;
            position: relative;
            margin-left: 0;
            @include media-breakpoint-down(md) {
                display: block;
            }
            > a, > .nolink {
                display: block;
                position: relative;
                border-bottom: 4px solid transparent;
                padding: 16px 0;
                color: #fff;
                font-size: 0.9rem;
                font-weight: 400;
                letter-spacing: .75px;
                line-height: 1.7em;
                text-decoration: none;
                text-transform: uppercase;
                @include transition(all .2s);
            }
            & + li {
                margin-left: 45px;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }

            &:hover, &.active, &.active-trail {
                > a, > .nolink {
                    border-bottom-color: #fff;
                    font-weight: 700;
                    @include media-breakpoint-down(md) {
                      border: 0;
                      color: #000;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    > ul {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            // Niv 2
            > ul {
                position: absolute;
                z-index: 1000;
                top: 100%;
                left: 0;
                margin: 0;
                padding: 0;
                width: 210px;
                min-width: 100%;
                background-color: map-get($theme-colors, primary);
                list-style: none;
                text-align: left;
                opacity: 0;
                visibility: hidden;
                @include transition(all .35s);

                @include media-breakpoint-down(md) {
                    display: none;
                    position: relative;
                    top: auto;
                    left: auto;
                    width: 100%;
                    background-color: transparentize(#fff,0.8);
                    opacity: 1;
                    visibility: visible;
                    text-align: center;
                }
                > li {
                    > a, > .nolink {
                        display: block;
                        padding: 10px 15px;
                        color: #fff;
                        font-size: 0.9rem;
                        line-height: 1.2em;
                        text-decoration: none;
                        @include transition(all .2s);
                        @include media-breakpoint-down(md) {
                          font-size: 1.1rem;
                          padding: 20px 15px;
                        }
                    }
                    & + li {
                        border-top: 1px solid transparentize(#fff,0.85);
                    }
                    &:hover, &.active {
                        > a, > .nolink {
                            background-color: darken(map-get($theme-colors, primary), 15%);
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        > .sublvl {
            > li {
                &.expanded {
                    position: relative;
                    overflow: hidden;
                }
                .expand {
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    right: 10px;
                    width: 60px;
                    height: 60px;
                    &:before {
                        content: '+';
                        display: block;
                        position: absolute;
                        top: 50%;
                        margin-top: -28px;
                        width: 100%;
                        color: #fff;
                        font-size: 45px;
                        line-height: 1em;
                        text-align: center;
                    }
                }
                &.open {
                    .expand {
                        &:before {
                            content: '-';
                        }
                    }
                    > ul {
                        display: block;
                    }
                }
            }
        }
    }
}

.secondary-nav {
    margin-top: 0;
    position: absolute;
    top: 35px;
    right: 50px;
    @include media-breakpoint-down(md) {
      position: static;
      border-top: 1px solid #fff;
      margin: 20px 0 0 0;
      padding: 20px 0 0 0;
    }
     > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
        @include clearfix;

        > li {
            display: inline-block;
            position: relative;

            @include media-breakpoint-down(md) {
                display: block;
            }
            > a, > .nolink {
                display: inline-block;
                position: relative;
                padding: 0;
                color: #555;
                font-size: 0.75rem;
                font-weight: 600;
                letter-spacing: .75px;
                line-height: 2em;
                text-decoration: none;
                @include transition(all .2s);
                @include media-breakpoint-down(md) {
                  color: #fff;
                  font-size: 1rem;
                  text-transform: uppercase;
                  font-weight: 400;
                  padding-bottom: 10px;
                }
            }
            & + li {
                margin-left: 15px;
                &:before {
                    content: 'I';
                    display: inline-block;
                    margin-right: 15px;
                    color: map-get($theme-colors, quaternary);
                    font-size: 0.75rem;
                    font-weight: 600;
                    letter-spacing: .75px;
                    line-height: 2em;
                }

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    &:before {
                        display: none;
                    }
                }
            }

            &:hover, &.active, &.active-trail {
                > a, > .nolink {
                    color: map-get($theme-colors, primary);
                    @include media-breakpoint-down(md) {
                      text-align: center;
                      color: #000;
                    }
                }
            }
        }
    }
}

.main-header.scrolled {
    #nav {
        > ul {
            > li {
                > a, > .nolink {

                }
            }
        }
    }
}


.trigger-menu {
  width: 40px;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: tranform .5s ease-in-out;
  -moz-transition: tranform .5s ease-in-out;
  -o-transition: tranform .5s ease-in-out;
  transition: tranform .5s ease-in-out;
  margin: 15px auto 0 auto;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: map-get($theme-colors, primary);
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &.open {
    position: fixed;
    top: 30px;
    right: 30px;
    span {
      background: #fff;
      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}

@include media-breakpoint-down(md) {
    nav#nav {
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 60px 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: transparentize(map-get($theme-colors, primary),0.02);
        overflow-x: auto;
        overflow-y: scroll;
        @include transition(all .35s);
    }
}

body.open-menu {
    @include media-breakpoint-down(md) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}
