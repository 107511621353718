.page-agenda {
  .round-content {
    padding: 40px 0 0 0;
    h2 {
      padding: 0 100px 20px 100px;
    }
    .event-content {
      background: map-get($theme-colors, quaternary);
      padding: 50px 50px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        padding: 80px 20px 20px 20px;
      }
      .views-row {
        width: 30%;
        margin: 50px 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        padding: 40px;
        background: rgba(0,0,0, .1);
        color: #fff;
        @include media-breakpoint-down(lg) {
          width: 45%;
          margin: 0 20px 50px 20px;
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          margin: 0 0 70px 0;
        }
        @include media-breakpoint-down(sm) {
          padding: 20px;
          margin: 0 0 100px 0;
        }
        &:first-child() {
          margin-left: 0;
          @include media-breakpoint-down(lg) {
            margin-left: 20px;
          }
          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
        &:last-child() {
          margin-right: 0;
          @include media-breakpoint-down(lg) {
            margin-right: 20px;
          }
          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
        }
        &:nth-child(4n) {
          margin-left: 0;
          @include media-breakpoint-down(lg) {
            margin-left: 20px;
          }
          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
          @include media-breakpoint-down(lg) {
            margin-right: 20px;
          }
          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
        }
        p {
          color: #fff;
        }
        a {
          color: #fff;
        }
        h3 {
          font-weight: 600;
        }
        .date {
          margin-top: -50px;
          margin-bottom: 20px;
        }
        .jour {
          font-size: 7rem;
          font-weight: 800;
        }
        .mois_annee {
          font-size: 1.7rem;
          font-weight: 800;
        }
        .category {
          text-transform: uppercase;
          font-size: .9rem;
        }
      }
    }
  }
  ul.pager {
    background: map_get($theme-colors, primary);
    padding: 40px 0;
    display: flex;
    justify-content: center;
    li {
      list-style: none;
      padding: 0 5px;
      margin: 0;
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}
.agenda-date {
  color: map_get($theme-colors, medium);
  display: inline-block;
  font-size: 5rem;
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
}
.agenda-category {
  color: map_get($theme-colors, darktext);
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
  float: right;
  @include media-breakpoint-down(sm) {
    float: none;
    margin-top: 15px;
  }
  &:before {
    content: '\f02b';
    font-family: Fontawesome;
    margin-right: 10px;
  }
}
.agenda-description {
  margin: 50px 0;
  color: map_get($theme-colors, darktext);
  line-height:2rem;
}
.register-form {
  margin: 40px 0;
  display: none;
}

