.subtitle {
  padding: 20px 0 50px 0;
}
.job-item {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid map_get($theme-colors, medium);
  h3 {
    color: map_get($theme-colors, primary);
    font-size: 1.5rem;
    font-weight: 600;
  }
  h4 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .btn1 {
    background: map_get($theme-colors, quaternary);
    padding: 10px 20px;
    border: 0;
    margin-top: 20px;
  }
}
