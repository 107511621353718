// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------
.banner {
  height: 350px;
  background: map_get($theme-colors, dark);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
