// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------
#leaderboard {
  .swiper-slide {
    height: 600px;
    .content-slider {
      background: map_get($theme-colors, tertiary);
      width: 650px;
      margin: 200px auto;
      padding: 40px;
      text-align: center;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
      @include media-breakpoint-down(sm) {
        width: auto;
        margin: 100px 40px;
        padding: 20px;
      }
      h1 {
        color: #fff;
        font-weight: 700;
        @include media-breakpoint-down(md) {
          font-size: 1.7rem;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
  .swiper-pagination-bullet {
    background: map_get($theme-colors, secondary);
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: map_get($theme-colors, primary);
  }
}
.intro {
  padding: 80px 0 0 0;
  height: 700px;
  background-repeat: no-repeat;
  background-position: 150% 0;
  position: relative;
  @include media-breakpoint-down(md) {
    background-position: left;
  }
  .effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      background: #c3d600;
      background: -moz-linear-gradient(top, #c3d600 0%, #3caf29 100%);
      background: -webkit-linear-gradient(top, #c3d600 0%,#3caf29 100%);
      background: linear-gradient(to bottom, #c3d600 0%,#3caf29 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c3d600', endColorstr='#3caf29',GradientType=0 );
      opacity: .55;
    }
  }
  .left-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 90px 0 0 0;
    z-index: 3;
    @include media-breakpoint-down(sm) {
      background: #ffffff;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: rgba(255,255,255,.98);
    }
    &:after {
      content: '';
      display: block;
      width: 150px;
      height: 2px;
      background: map_get($theme-colors, secondary);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -75px;
      z-index: 3;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .content {
      padding-right: 120px;
      @include media-breakpoint-down(md) {
        padding-right: 20px;
      }
    }
    h2 {
      color: map_get($theme-colors, dark);
      font-size: .95rem;
      text-transform: uppercase;
    }
    h3 {
      color: map_get($theme-colors, quaternary);
      font-weight: 500;
      line-height: 2.5rem;
    }
    p {
      font-size: .9rem;
      color: map_get($theme-colors, dark);
      line-height: 2rem;
    }
    a {
      color: map_get($theme-colors, primary);
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
.news {
  position: relative;
  z-index: 4;
  h2 {
    margin-bottom: 40px;
    color: map-get($theme-colors, quaternary);
    text-align: left;
  }
  .news-home {
    margin-top: -100px;
    background: #fff;
    padding: 60px;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-top: 0;
      padding: 20px;
      text-align: center;
    }
    .all-news {
      position: absolute;
      width: 27%;
      right: 60px;
      top: 135px;
      height: 260px;
      display: block;
      background: map_get($theme-colors, tertiary);
      text-align: center;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      padding: 100px 0 0 0;
      font-weight: 600;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      @include transition(all .3s ease-in-out);
      @include media-breakpoint-down(md) {
        position: static;
        width: auto;
        height: auto;
        display: inline-block;
        margin: 20px auto;
        padding: 20px;
      }
      &:hover {
        background: map-get($theme-colors, secondary);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
    }
  }
  .view-actualites {
    position: relative;
    text-align: left;
    .view-content {
      display: flex;
      justify-content: flex-start;
      @include media-breakpoint-down(md) {
        justify-content: space-between;
      }
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
      .views-row {
        width: 30%;
        margin: 0 25px;
        @include media-breakpoint-down(md) {
          width: 40%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin: 0 10px;
        }
        &:first-child() {
          margin-left: 0;
          @include media-breakpoint-down(sm) {
            margin-left: 10px;
          }
        }
        .news-item {
          position: relative;
          top: 0;
          @include transition(all .2s ease-in-out);
          &:hover {
            top: -30px;
          }
        }
        .news-pic {
          height: 260px;
          background-size: cover;
          background-repeat:no-repeat;
          background-position: center;
        }
        h3 {
          font-size: 1.2rem;
          color: map-get($theme-colors, quaternary);
          margin: 30px 0 10px 0;
        }
        p {
          font-size: .9rem;
          color: map_get($theme-colors, dark);
          line-height: 2rem;
        }
      }
    }
  }
}
.node-type-homepage {
  .view-actualites {
    .round-content {
      padding: 0;
      border-radius: 0;
    }
  }
}
.institution {
  margin-bottom: 80px;
}
.institution-container {
  background-image: url(../images/bg-institution.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 300px 0 50px 0;
  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
  .search-box {
    margin: 0 150px;
    background: map_get($theme-colors, primary);
    padding: 50px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    @include media-breakpoint-down(sm) {
      margin: 0 20px;
      padding: 20px;
    }
    .pretitle {
      text-transform: uppercase;
      font-size: .9rem;
    }
    h2 {
      color: #fff;
    }
    form {
      > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        > div {
          width: 30%;
          margin-bottom: 0;
          margin: 0 10px;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin: 10px;
          }
          &.form-item-activites {
            position: relative;
            &:after {
              content: '\f002';
              font-family: Fontawesome;
              position: absolute;
              right: 10px;
              top: 15px;
            }
            input{
              padding-right: 50px;
            }
          }
        }
        .activites {
          background: transparent;
          border: 1px solid #fff;
          color: #fff;
          outline: none;
          position: relative;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
        }
        .form-type-select {
          border: 1px solid #fff;
          color: #fff;
          position: relative;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          &:after {
            content: '\f107';
            font-family: Fontawesome;
            position: absolute;
            top: 12px;
            right: 10px;
            padding-left: 10px;
            border-left: 1px solid #fff;
            z-index: 2;
          }
          select {
            color: #fff;
            border: none;
            box-shadow: none;
            background-color: transparent;
            background-image: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            outline: none;
            position: relative;
            z-index: 3;
            option {
              color: map_get($theme-colors, primary);
            }
          }
        }
        input.form-submit {
          background-color: darken(map-get($theme-colors, primary), 10%);
          border-color: darken(map-get($theme-colors, primary), 10%);
          padding: 10px 30px;
          @include transition(all .2s eas-in-out);
          &:hover {
            color: map_get($theme-colors, primary);
            background: #fff;
            border-color: #fff;
          }
        }
      }
    }
  }
}
.agenda-home {
  background: map_get($theme-colors, quaternary);
  padding: 100px 0;
  .container {
    padding: 0;
  }
  .round-content {
    background: transparent;
    padding: 0;
    .event-content {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
      > div {
        margin: 0 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        padding: 40px;
        background: rgba(0,0,0, .1);
        color: #fff;
        @include media-breakpoint-down(md) {
          width: 100%;
          margin: 40px 0;
        }
        @include media-breakpoint-down(sm) {
          margin: 40px 10px;
        }
        &:first-child() {
          margin-left: 0;
          @include media-breakpoint-down(sm) {
            margin-left: 10px;
          }
        }
        &:last-child() {
          margin-right: 0;
          @include media-breakpoint-down(sm) {
            margin-right: 10px;
          }
        }
        p {
          color: #fff;
        }
        a {
          color: #fff;
        }
        h3 {
          font-weight: 600;
        }
        .date {
          margin-top: -50px;
          margin-bottom: 20px;
        }
        .jour {
          font-size: 7rem;
          font-weight: 800;
        }
        .mois_annee {
          font-size: 1.7rem;
          font-weight: 800;
        }
        .category {
          text-transform: uppercase;
          font-size: .9rem;
        }
      }
    }
    .event-title {
      display: none;
    }
  }
  .all-link {
    text-align: right;
    margin-top: 50px;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    a {
      color: #fff;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
}
