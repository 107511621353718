.node-type-carte {
  .round-content {
    padding-top: 50px;
  }
  ul.anchor-menu {
    margin: 0;
    padding: 0 0 40px 0;
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      @include media-breakpoint-down(lg) {
        margin-right: 10px;
        margin-bottom: 15px;
      }
      a {
        color: map_get($theme-colors, darktext);
        text-decoration: none;
        text-transform: uppercase;
        padding: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        @include transition(all .2s ease-in-out);
        &:hover {
          background: rgba(0,0,0,.05);
        }
      }
    }
  }
  .description {
    color: map_get($theme-colors, darktext);
    line-height: 2rem;
    margin-bottom: 50px;
  }
  .intro-text {
    margin-bottom: 50px;
  }
  .avantages {
    margin-bottom: 50px;
    .avantage-item {
      padding: 30px;
      margin-bottom: 20px;
      &.orange {
        background-color: map-get($theme-colors, tertiary);
      }
      &.bleu_gris {
        background-color: map-get($theme-colors, blue);
      }
      &.rose {
        background-color: map-get($theme-colors, pink);
      }
      &.vert {
        background-color: map-get($theme-colors, primary);
      }
      h3 {
        color: #fff;
      }
      p, ul li, a {
        color: #fff;
      }
      figure {
        background: #fff;
        padding: 10px;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
#proposer {
  margin: 40px 0;
}
