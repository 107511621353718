// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body {
  padding-top: 220px;
	background-color: #fff;
	color: #292b2c;
	font-size: 1rem;
	line-height: 1.5em;
}

.iframe-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  padding-top: percentage(9 / 16);
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
#main {
  margin-top: 0;
  @include transition(all .35s ease-in-out);
  @include media-breakpoint-down(md) {
    margin-top: -60px;
  }
  &.scrolled {
    margin-top: -93px;
    @include transition(all .35s ease-in-out);
  }
}
.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.page-user {
  .banner {
    display: none;
  }
  .breadcrumb, #user-login {
    @extend .container;
    margin-top: 40px;
  }
  .description {
    margin-top: 5px;
  }
  h1, h2, ul, .profile {
    @extend .container;
  }
  h1.main-title {
    color: map_get($theme-colors, darktext);
  }
  .banner {
    background-color: transparent;
    background-image: none;
  }
}
.page-search404 {
  #main {
    .breadcrumb, h1, h2, .messages, ul, ol, form{
      @extend .container;
    }
    .breadcrumb, h1, .messages {
      margin-top: 40px;
    }
    #edit-submit {
      margin: 10px 0 20px 0;
    }
  }
}
.breadcrumb {
  display: none;
}
.round-content {
  background: #fff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 100px;
  @include media-breakpoint-down(md) {
    padding: 30px;
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}
body.node-type-page {
  background: map_get($theme-colors, light);
}
.img-left {
  float: left;
  margin: 0 40px 20px 0;
}
