
// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 18px 30px;
  box-shadow: none !important;
  font-family: $font-family-sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.45em;
  white-space: normal;
  text-decoration: none !important;
  text-transform: uppercase;
  @include transition(all .35s);
  &.btn-primary, &.btn-default, &.btn1 {
    background-color: map-get($theme-colors, primary);
    border-color: map-get($theme-colors, primary);
    color: #fff;
    &:hover {
      background-color: darken(map-get($theme-colors, primary), 10%);
      border-color: darken(map-get($theme-colors, primary), 10%);
      color: #fff;
    }
  }
  &.btn-secondary, &.btn-border, &.btn2 {
    background-color: #fff;
    border-color: map-get($theme-colors, primary);
    color: map-get($theme-colors, primary);
    &:hover {
      background-color: map-get($theme-colors, primary);
      border-color: map-get($theme-colors, primary);
      color: #fff;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
  cursor: pointer;
}
